<template>
  <div>
    <a-card
      :style="{ marginBottom: '1em' }"
      :header="$t('common.search')"
      :editable="false"
    >
      <template
        #header-buttons
      >
        <div class="is-pulled-right is-flex buttons">
          <a-select
            :manual-doc="userDoc"
            field="routes.customers.orderBy"
            :label="$t('common.order')"
            :options="keys($store.getters['customers/orderOptions'])"
            :labels="$store.getters['customers/orderOptions']"
          />
        </div>
      </template>

      <a-input
        :style="{ marginTop: '1em' }"
        :manual-doc="userDoc"
        field="routes.customers.search"
        :label="$t('common.quick_search')"
      />
    </a-card>
    <a-table
      :columns="user.routes.customers.columns"
      :data="$store.getters['customers/displayedItems']"
    />
    <more-docs-button
      module="customers"
    />
    <add-doc-button
      :doc-props="{}"
    />
  </div>
</template>

<script>
import ATable from '@/components/ATable'
import MoreDocsButton from '@/components/MoreDocsButton'
import { mapGetters, mapActions } from 'vuex'
import { keys } from 'lodash'

export default {
  components: {
    MoreDocsButton
  },
  data() {
    return {
      userDoc: { module: 'user' }
    }
  },
  created () {
    this.$store.dispatch('customers/fetchDocs')
  },
  methods: {
    keys
  }
}
</script>
